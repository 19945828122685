import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
  // エラーページへリダイレクトさせなくてもよいURLをホワイトリストで定義
  private readonly whiteLists = ['/classi/api/settings/student_order', '/classi/api/menu'];

  constructor(private errorService: ErrorService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handler$ = next.handle(request);

    if (this.whiteLists.some((url) => request.url.includes(url))) {
      return handler$;
    }

    return handler$.pipe(catchError((err) => this.handleError(err)));
  }

  // eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
  private handleError(err: HttpErrorResponse): Observable<any> {
    try {
      // サーバでエラーが発生したときはJSONで返ってくる
      if (err.status === 401) {
        // パスが文字列で渡されるので、 Router#navigate は使えない
        location.assign(err.error.redirect_url);
      } else if (err.status === 403) {
        this.errorService.redirectTo403();
      } else if (err.status === 404) {
        this.errorService.redirectTo404();
      } else {
        const { title, message } = err.error;
        this.errorService.redirectToErrorPage({ title, message });
        return _throw(err);
      }
      return NEVER;
    } catch (e) {
      // サーバがダウンしてるなど
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.errorService.redirectToErrorPage({ title: 'システムエラー', message: (err as any).message || 'システムエラーが発生しました。' });
      return _throw(e);
    }
  }
}

<header id="global-header" class="spen-ly-global-header">
  <div class="spen-util-inner">
    <!-- title -->
    <h1 myDirective class="page-title">{{ title$ | async }}</h1>
    <!-- logo -->
    <a class="header-content classi-logo" href="{{ homeUrl }}">
      <img src="/assets/img/common/logo-classi.png" />
    </a>
    <!-- user name -->
    <a *ngIf="!isError" class="header-content user-information">
      <span class="user-name">{{ user?.name }}</span>
    </a>
    <!-- title area -->
    <div class="header-content dummy-content"></div>
    <a class="header-content" href="{{ helpUrl }}" target="_blank" rel="noopener noreferrer">
      <i class="fa fa-question"></i>
      <span class="content-text">ヘルプ</span>
    </a>
    <div *ngIf="!isError" class="header-content" (click)="openMenu()">
      <i class="fa fa-th"></i>
      <span class="content-text">メニュー</span>
    </div>
  </div>
</header>

<app-global-menu *ngIf="!isError" [user]="user"></app-global-menu>

<footer class="spen-ly-global-footer">
  <div class="spen-util-inner">
    <div class="footer-link-list">
      <div class="footer-link">
        <a href="{{ helpUrl }}" target="_blank" rel="noopener noreferrer" class="spen-util-link">ヘルプページ</a>
      </div>
      <div class="footer-link" *ngIf="isTeacher">
        <a href="{{ inquiryUrl }}" target="_blank" rel="noopener noreferrer" class="spen-util-link">お問い合わせ</a>
      </div>
      <div class="footer-link" *ngIf="isParent">
        <a href="{{ parentsNoticeUrl }}" target="_blank" rel="noopener noreferrer" class="spen-util-link">利用に関する注意事項</a>
      </div>
      <div class="footer-link" *ngIf="isParent">
        <a href="{{ studentsNoticeUrl }}" target="_blank" rel="noopener noreferrer" class="spen-util-link">利用に関する注意事項(生徒)</a>
      </div>
      <div class="footer-link" *ngIf="isStudent">
        <a href="{{ studentsNoticeUrl }}" target="_blank" rel="noopener noreferrer" class="spen-util-link">利用に関する注意事項</a>
      </div>
    </div>
    <p class="copy-right-text">Copyright © Classi Corp. All Rights Reserved.</p>
  </div>
</footer>

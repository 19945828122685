/**
 * メニュー情報を扱うサービス
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GlobalMenuItem } from '../../domains';

const timeoutMs = 5_000;

@Injectable()
export class MenuService {
  static readonly menuUrl = `${environment.classi_api_url}/menu`;

  constructor(private http: HttpClient) {}

  // GET
  // TODO エラーハンドリングを追加する
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMenu(): Observable<any> {
    return this.http.get<GlobalMenuItem[]>(MenuService.menuUrl).pipe(
      timeout(timeoutMs),
      catchError(() => of([] as GlobalMenuItem[])),
    );
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MenuService } from '../../../core/services/menu.service';
import { GlobalMenuItem, User } from '../../../domains/index';

@Component({
  selector: 'app-global-menu',
  templateUrl: './global-menu.component.html',
})
export class GlobalMenuComponent implements OnInit, OnDestroy {
  @Input() user: User;
  globalMenuData: GlobalMenuItem[] = [];
  isOpenMenu = false;
  homeUrl = environment.home_url;

  private fetchGlobalMenuListRequest$ = new Subject();
  private onDestroy$ = new Subject();

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.fetchGlobalMenuListRequest$.pipe(throttleTime(1000), takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.globalMenuData.length > 0) {
        this.isOpenMenu = true;
        return;
      }
      this.menuService.getMenu().subscribe((menuItemList) => {
        this.globalMenuData = menuItemList;
        this.isOpenMenu = true;
      });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  toggleMenu(): void {
    this.isOpenMenu ? this.closeMenu() : this.showMenu();
  }

  showMenu(): void {
    this.fetchGlobalMenuListRequest$.next();
  }

  closeMenu(): void {
    this.isOpenMenu = false;
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { ModalService } from './services/modal.service';

@NgModule({
  imports: [PortalModule, OverlayModule],
  declarations: [],
  providers: [ModalService],
})
export class ModalModule {}

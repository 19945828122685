<div class="spen-mod-paginate">
  <span>
    <ng-container *ngIf="cannotMoveToPrev; else linkLeft">
      <i class="fa fa-chevron-left"></i>
    </ng-container>

    <ng-template #linkLeft>
      <a (click)="!!pageMoveTo(-1)">
        <i class="fa fa-chevron-left"></i>
      </a>
    </ng-template>
  </span>

  <ng-container *ngIf="!displayFirst">
    <ng-container *ngTemplateOutlet="button; context: { page: 1 }"></ng-container>
  </ng-container>

  <span *ngIf="visibleLeftGap" class="gap">･･･</span>

  <ng-container *ngFor="let page of activePages">
    <ng-container *ngTemplateOutlet="button; context: { page: page }"></ng-container>
  </ng-container>

  <span *ngIf="visibleRightGap" class="gap">･･･</span>

  <ng-container *ngIf="!displayLast">
    <ng-container *ngTemplateOutlet="button; context: { page: lastPage }"></ng-container>
  </ng-container>

  <span class="last">
    <ng-container *ngIf="cannotMoveToNext; else linkRight">
      <i class="fa fa-chevron-right"></i>
    </ng-container>

    <ng-template #linkRight>
      <a (click)="!!pageMoveTo(1)">
        <i class="fa fa-chevron-right"></i>
      </a>
    </ng-template>
  </span>
</div>

<ng-template #button let-page="page">
  <ng-container *ngIf="page === currentPage; else link">
    <span class="current">{{ page }}</span>
  </ng-container>

  <ng-template #link>
    <span
      ><a (click)="!!currentPageChange.emit(page)">{{ page }}</a></span
    >
  </ng-template>
</ng-template>

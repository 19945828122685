import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { User, UserTypeId } from '../../../domains';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() user: User;

  helpUrl = environment.help_url;
  inquiryUrl = `${environment.home_url}/inquiry`;
  studentsNoticeUrl = environment.students_notice_url;
  parentsNoticeUrl = environment.parents_notice_url;

  get isTeacher() {
    return this.user && this.user.user_type_id === UserTypeId.Teacher;
  }

  get isStudent() {
    return this.user && this.user.user_type_id === UserTypeId.Student;
  }

  get isParent() {
    return this.user && this.user.user_type_id === UserTypeId.Guardian;
  }
}

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
// eslint-disable-next-line max-len
import { ClassiAppsDescribeModalComponent } from './applications/components/classi-apps-describe-modal/classi-apps-describe-modal.component';
import { CoreModule } from './core/core.module';
import { ErrorHandlerFactory } from './core/services/error-handler.factory';
import { UploadResultModalComponent, UploadUploadingModalComponent } from './entry-components';
import { ModalModule } from './modal/modal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent, UploadResultModalComponent, UploadUploadingModalComponent, ClassiAppsDescribeModalComponent],
  imports: [CoreModule, AppRoutingModule, BrowserModule, ModalModule, NgbModule],
  bootstrap: [AppComponent],
  providers: [{ provide: ErrorHandler, useFactory: ErrorHandlerFactory.create }],
})
export class AppModule {}

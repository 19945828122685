import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  /** ページのタイトル */
  heading: string;

  /** エラーの内容 */
  message: string;

  constructor(
    private readonly router: Router,
    private readonly errorService: ErrorService,
  ) {}

  ngOnInit() {
    this.errorService.errorPayload.subscribe((payload) => {
      // 表示させるべきコンテンツが無い場合(エラーページでリロードされた場合など)
      // トップページへ戻す
      if (payload === null) {
        this.router.navigate(['/']);
        return;
      }

      this.heading = payload.title;
      this.message = payload.message;
    });
  }
}

/* eslint-disable max-len, @typescript-eslint/quotes */
import { UserTypeId } from '../../../domains';

/**
 * ユーザ情報取得APIモック
 */

export const GA4_USER = {
  ga4_user: {
    user_id: 12345,
    user_type_id: UserTypeId.Teacher,
    user_type_name: '',
    teacher_role_id: '',
    teacher_role_name: '',
    school_id: 1,
    school_name: '',
    benesse_school_id: 1,
    school_stage_id: 1,
    school_stage_name: '',
    grade_code: '',
    grade_id: '',
    grade_name: '',
    classroom_id: '',
    classroom_name: '',
    classi_type: 1,
    usage_type1: 0,
    usage_type2: 0,
    usage_type3: 0,
  },
};

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Contents } from '../../domains/contents';

@Injectable()
export class ContentsService {
  constructor(private http: HttpClient) {}

  getContents(applicationId: number, page = 1): Observable<Contents> {
    page = page || 1;
    const params = new HttpParams().append('application_id', `${applicationId}`).append('page', `${page}`);
    return this.http.get<Contents>(`${environment.classi_api_url}/contents`, { params: params });
  }

  getSampleContents(applicationId: number, page = 1): Observable<Contents> {
    page = page || 1;
    const params = new HttpParams().append('application_id', `${applicationId}`).append('page', `${page}`);
    return this.http.get<Contents>(`${environment.classi_api_url}/sample_contents`, { params: params });
  }
}

export enum UserRole {
  // その他（生徒や保護者）
  Other = 0,

  // 管理責任者
  ChiefAdministrator = 1,

  // 管理者
  Administrator = 2,

  // 機能限定管理者（成績）
  LimitedAdministrator = 3,

  // 一般
  General = 4,

  // 機能限定管理者（成績+授業）
  LimitedAdministrator2 = 5,
}

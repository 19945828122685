/* eslint-disable max-len, @typescript-eslint/quotes, quote-props */
/**
 * [admin] 連携サービス一覧APIのモック
 */
export const ADMIN_APPLICATIONS = [
  {
    id: 12345,
    name: 'ClassiNOTE',
    uid: '188e3fdae13b43c0d5fd930fd5d0a51d5c279f4343d64ac4376c9b0a4c5db3a7',
    secret: '5b1b228d139b05b1a1b1548ca26cf2686eab2af59e6d7b4bc18b387e62995848',
    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    scopes: 'openid profile classroom picture group usage_type classi_type',
    developer_id: 1,
    developer_type: 'Developer',
    company_name: '開発会社1',
    status: 'active',
    application_category_id: 1,
    category_name: '英語4技能',
    caption: 'test',
    specific_description: 'testtest',
    pricing: 'testtesttest',
    notes: 'notes',
    howtouse_url: 'http://localhost',
    demo_enabled: false,
    demo_url: '',
    logo_url: 'https://classi.jp/images/pc/usage/img_teacher.png',
    icon_url: 'https://classi.jp/images/splash.v1.w800.png',
    entry_form_url: 'https://classi.jp/images/splash.v1.w800.png',
    coms_url: 'https://coms.jp/classinote',
    manual_video_url: 'https://classi.jp/images/splash.v1.w800.png',
    manual_document_url: 'https://classi.jp/images/splash.v1.w800.png',
    is_first_party: false,
    partnership_type: 'id_federation',
    application_user_types: [
      { id: 1, user_type_id: 1 },
      { id: 2, user_type_id: 2 },
    ],
    application_devices: [
      { id: 1, device_type: 1 },
      { id: 2, device_type: 2 },
    ],
    settable: true,
    show_on_promotion_page: true,
    created_at: '2016-07-27 16:05:47',
    updated_at: '2017-04-28 14:34:48',
  },
  {
    id: 12346,
    name: 'EnglishCentral',
    uid: '0cf279384745ba15351680a990588942a6517e4ad55a71dd0c4ba9a3c316afb6',
    secret: '8756afd06b67f86314fc074a92bdcedbee0cecb46a4926b99b0be2eca55645ca',
    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    scopes: 'openid profile classroom',
    developer_id: 1,
    developer_type: 'Developer',
    company_name: '開発会社1',
    status: 'active',
    application_category_id: 2,
    category_name: 'プログラミング',
    caption: '英語４技能',
    specific_description: '英語英語',
    pricing: '1,340円',
    notes: 'notes notes',
    howtouse_url: 'http://localhost',
    demo_enabled: false,
    demo_url: '',
    logo_url: 'https://classi.jp/images/pc/usage/img_teacher.png',
    icon_url: 'https://classi.jp/images/b.png',
    manual_video_url: 'https://classi.jp/images/splash.v1.w800.png',
    manual_document_url: 'https://classi.jp/images/splash.v1.w800.png',
    entry_form_url: 'http://localhost/aaa.pdf',
    coms_url: 'https://coms.jp/ec',
    is_first_party: false,
    partnership_type: 'id_federation',
    application_user_types: [
      { id: 3, user_type_id: 1 },
      { id: 4, user_type_id: 2 },
    ],
    application_devices: [
      { id: 1, device_type: 1 },
      { id: 2, device_type: 2 },
    ],
    settable: false,
    show_on_promotion_page: true,
    created_at: '2016-08-09 16:02:06',
    updated_at: '2016-08-09 16:02:06',
  },
  {
    id: 12347,
    name: 'eポートフォリオ',
    uid: 'portfoliouid',
    secret: 'portfoliosecret',
    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    scopes: 'openid profile classroom',
    developer_id: 1,
    developer_type: 'Developer',
    company_name: '開発会社1',
    status: 'active',
    application_category_id: 2,
    categoryç: 'プログラミング',
    caption: '英語４技能',
    specific_description: '英語英語',
    pricing: '1,340円',
    notes: 'notes notes',
    howtouse_url: 'http://localhost',
    demo_enabled: false,
    demo_url: '',
    logo_url: 'https://classi.jp/images/pc/usage/img_teacher.png',
    icon_url: 'https://classi.jp/images/b.png',
    manual_video_url: 'https://classi.jp/images/splash.v1.w800.png',
    manual_document_url: 'https://classi.jp/images/splash.v1.w800.png',
    entry_form_url: 'http://localhost/aaa.pdf',
    coms_url: 'https://coms.jp/ep',
    is_first_party: true,
    partnership_type: 'id_federation',
    application_user_types: [
      {
        id: 5,
        user_type_id: 1,
      },
      {
        id: 6,
        user_type_id: 2,
      },
    ],
    application_devices: [
      { id: 1, device_type: 1 },
      { id: 2, device_type: 2 },
    ],
    settable: false,
    show_on_promotion_page: false,
    created_at: '2016-08-09 16:02:06',
    updated_at: '2016-08-09 16:02:06',
  },
  {
    id: 12348,
    name: 'Think and Quest 学ぶキミを引き出す 日本史[原始・古代～近世]',
    uid: 'portfoliouid',
    secret: 'portfoliosecret',
    redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    scopes: 'openid profile classroom',
    developer_id: 1,
    developer_type: 'Developer',
    company_name: '開発会社1',
    status: 'active',
    settable: false,
    show_on_promotion_page: false,
    created_at: '2016-08-09 16:02:06',
    updated_at: '2016-08-09 16:02:06',
  },
];

export const ADMIN_APPLICATION_TEMPLATE = {
  id: null,
  name: 'NEW!! ClassiNOTE',
  uid: '188e3fdae13b43c0d5fd930fd5d0a51d5c279f4343d64ac4376c9b0a4c5db3a7',
  secret: '5b1b228d139b05b1a1b1548ca26cf2686eab2af59e6d7b4bc18b387e62995848',
  redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
  scopes: 'openid profile classroom picture group usage_type classi_type',
  developer_id: 1,
  developer_type: 'Developer',
  status: 'active',
  application_category_id: 1,
  caption: 'test',
  specific_description: 'testtest',
  pricing: '100円/月',
  notes: 'notes',
  howtouse_url: 'http://localhost',
  demo_enabled: false,
  demo_url: '',
  logo_url: 'https://classi.jp/images/pc/usage/img_teacher.png',
  icon_url: 'https://classi.jp/images/splash.v1.w800.png',
  entry_form_url: 'https://classi.jp/images/splash.v1.w800.png',
  coms_url: 'https://coms.jp/new_classinote',
  is_first_party: false,
  partnership_type: 'id_federation',
  application_user_types: [
    { id: 3, user_type_id: 1 },
    { id: 4, user_type_id: 2 },
  ],
  application_devices: [{ id: 1, device_type: 1 }],
  settable: true,
  show_on_promotion_page: true,
  created_at: '2016-07-27 16:05:47',
  updated_at: '2017-04-28 14:34:48',
};

import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { environment } from '../../environments/environment';

import { SharedModule } from '../shared/shared.module';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';

import { TeacherGuard } from './guard/teacher.guard';
import { ApplicationsService } from './services/applications.service';
import { ContentsService } from './services/contents.service';

import { ErrorService } from './services/error.service';
import { HttpServiceInterceptor } from './services/http-service-interceptor';
import { InMemoryDataService } from './services/in-memory-data-service';
import { MeService } from './services/me.service';
import { MenuService } from './services/menu.service';
import { UsingApplicationsService } from './services/using-applications.service';

const COMPONENTS = [ErrorPageComponent, NotFoundPageComponent, ForbiddenPageComponent];

const forDevelopment = [
  HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    delay: 0,
    dataEncapsulation: false,
    passThruUnknownUrl: true,
  }),
];

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-TOKEN',
      headerName: 'X-CSRF-Token',
    }),
    SharedModule,
    environment.production ? [] : forDevelopment,
  ],
  declarations: [COMPONENTS],
  exports: [HttpClientModule, COMPONENTS],
  providers: [
    ApplicationsService,
    ErrorService,
    MeService,
    MenuService,
    UsingApplicationsService,
    ContentsService,
    TeacherGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  /**
   * CoreModule はルートのモジュールで一度だけ読み込まれるようにする
   * @see {@link https://angular.io/guide/ngmodule#prevent-reimport-of-the-coremodule}
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * [admin] 連携サービスログインURLのモック
 */
export const ADMIN_APPLICATION_USER_TYPES = [
  {
    id: 1,
    user_type_id: 1,
    web_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
    ios_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
    android_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
  },
  {
    id: 2,
    user_type_id: 2,
    web_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
    ios_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
    android_authentication_url: 'https://classi.jp/images/splash.v1.w800.png',
  },
];

export const ADMIN_APPLICATION_USER_TYPE_TEMPLATE = {
  id: null,
  user_type_id: null,
  web_authentication_url: 'https://new/classi.jp/images/splash.v1.w800.png',
  ios_authentication_url: 'https://new/classi.jp/images/splash.v1.w800.png',
  android_authentication_url: 'https://new/classi.jp/images/splash.v1.w800.png',
};

/* eslint-disable max-len, @typescript-eslint/quotes */
import { UserRole, UserTypeId } from '../../../domains';

/**
 * ユーザ情報取得APIモック
 * @type {{id: number; name: string; user_type_id: number; user_icon: string;}}
 */

export const ME = {
  id: 12345,
  name: '山田 太郎',
  user_role: UserRole.Administrator,
  user_type_id: UserTypeId.Teacher,
  user_icon: 'http://platform.classi.jp/hoge.jpg',
  school_stage_id: 2,
  school_this_year: 2017,
};

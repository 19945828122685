import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';

Sentry.init({
  dsn: environment.sentry,
  environment: environment.environmentName,
  release: environment.sentryReleaseName,

  // PERFORMANCE MONITORINGの追加
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: environment.sentryTracesSampleRate,

  ignoreErrors: [
    /\bCan't find variable: removeAllHighlightsForSB\b/,
    /\bonSvFinishLoading is not defined\b/,
    /\bundefined is not an object \(evaluating 'window.webkit.messageHandlers.selectedDebugHandler.postMessage'\)$/,
  ],
});
Sentry.setTag('deploy_image_tag', environment.deployImageTag);

@Injectable()
export class SentryHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    // ChunkLoadErrorの対応
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.alert('読み込み中にエラーが発生しました。ページの再読み込みを行います。');
      window.location.reload();
    } else {
      Sentry.captureException(error.originalError || error);
      throw error;
    }
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appGa4]',
})
export class Ga4Directive {
  @Input()
  gaEventCategory: string | void;

  @Input()
  gaEventLabel: string | void;

  @Input()
  gaEventLocation: string | void;

  @Input()
  gaEventClickParts: string | void;

  @Input()
  gaEventFreeKey: string | void;

  @Input()
  gaEventFreeValue: string | void;

  @Input()
  gaEventFreeKey2: string | void;

  @Input()
  gaEventFreeValue2: string | void;

  @HostListener('click')
  onClick() {
    if (environment.ga4MeasurementId === '') {
      return;
    }

    this.trackEvent();
  }

  /**
   * イベントトラッキングを行う
   */
  private trackEvent() {
    const eventParam: {} = this.fetchEventParam();
    this.setFreeParam(eventParam);

    gtag('event', 'custom_event', eventParam);
  }

  /**
   * イベントトラッキングのパラメータを取得する
   * @returns イベントトラッキングのパラメータ
   */
  private fetchEventParam(): {} {
    return {
      function: this.gaEventCategory,
      sub_function: this.gaEventLabel,
      location: this.gaEventLocation,
      click_parts: this.gaEventClickParts,
    };
  }

  /**
   * 任意のパラメータを設定する
   * @param eventParam イベントトラッキングのパラメータ
   */
  private setFreeParam(eventParam: {}) {
    if (this.gaEventFreeKey) {
      eventParam[this.gaEventFreeKey] = this.gaEventFreeValue;
    }

    if (this.gaEventFreeKey2) {
      eventParam[this.gaEventFreeKey2] = this.gaEventFreeValue2;
    }
  }
}

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * @file mock-application-case-studies.ts.
 */
export const APPLICATION_CASE_STUDIES = [
  {
    title: '大阪第一高等学校',
    image_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/case_studies/5.jpg',
    elements: [
      {
        subject: '①週末課題での活用',
        body: '課題プリントとして配布し、授業で教材にでてくる単語を確認し、音源ファイルを生徒に配布。',
      },
      {
        subject: '②自学習',
        body: '生徒がWebサイトにログインして学習することができるので教員がニュースを選び定期考査の出題範囲にし自学習でも活用しています。',
      },
    ],
  },
  {
    title: '大阪第二高等学校',
    image_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/10/case_studies/8.jpg',
    elements: [
      {
        subject: '①週末課題での活用',
        body: '課題プリントとして配布し、授業で教材にでてくる単語を確認し、音源ファイルを生徒に配布。',
      },
      {
        subject: '②自学習',
        body: '生徒がWebサイトにログインして学習することができるので教員がニュースを選び定期考査の出題範囲にし自学習でも活用しています。',
      },
    ],
  },
];

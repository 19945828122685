import { Component, Input, OnInit } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { filter, first, share } from 'rxjs/operators';
import { Ga4DataService } from '../../../../app/ga4-data.service';
import { environment } from '../../../../environments/environment';
import { MeService } from '../../../core/services/me.service';
import { User } from '../../../domains';

@Component({
  selector: 'app-classi-service',
  templateUrl: './classi-service.component.html',
  styleUrls: ['./classi-service.component.scss'],
})
export class ClassiServiceComponent implements OnInit {
  @Input() isError = false;
  user: User;

  readonly isClassiNative = /classi/i.test(window.navigator.userAgent);
  constructor(
    private meService: MeService,
    private readonly ga4DataService: Ga4DataService,
  ) {}

  ngOnInit() {
    // エラー状態だったら何もしない
    if (this.isError) {
      return;
    }
    this.meService
      .getMe()
      .pipe(
        share(),
        filter((user) => user !== null),
        first(),
      )
      .subscribe((user) => {
        this.user = user;
      });

    if (environment.ga4MeasurementId === '') {
      return;
    }

    // register google tag manager
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.ga4MeasurementId}&l=gDataLayer`;
    document.head.appendChild(gTagManagerScript);

    // register google analytics
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.gDataLayer = window.gDataLayer || [];
      function gtag() { gDataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.ga4MeasurementId}');
    `;
    document.head.appendChild(gaScript);

    this.ga4DataService.getGa4User().subscribe(({ ga4_user }) => {
      gtag('set', 'user_properties', ga4_user);
      this.#setTagsToSentry({
        userId: ga4_user.user_id,
        schoolId: ga4_user.school_id,
        userTypeId: ga4_user.user_type_id,
        userTypeName: ga4_user.user_type_name,
      });
    });
  }

  #setTagsToSentry({
    userId,
    schoolId,
    userTypeId,
    userTypeName,
  }: Record<'userId' | 'schoolId' | 'userTypeId', number> & { userTypeName: string }) {
    if (!Sentry) return;
    const scope = Sentry.getCurrentScope();
    scope.setUser({ id: userId });
    scope.setTag('school_id', schoolId);
    scope.setTag('user_type', userTypeId);
    scope.setTag('user_type_name', userTypeName);
  }
}

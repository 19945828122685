/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * [admin] 連携サービス受注一覧APIのモック
 * @type {{school_id: number; school_name: string; orders: {id: number; application_id: number; application_name: string; school_year: number; student_limit: number; start_at: string; end_at: string; updated_at: string}[]}}
 */
export const ADMIN_APPLICATION_ORDERS = {
  school_id: 123456789,
  school_name: 'example school',
  orders: [
    {
      id: 341738763,
      application_id: 12345,
      application_name: 'ClassiNOTE',
      school_year: 2017,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'normal',
      order_type_display: '受注',
      start_at: '2017-10-01T00:00:00+09:00',
      end_at: '2020-10-01T00:00:00+09:00',
      updated_at: '2017-12-01T00:00:00+09:00',
    },
    {
      id: 341738764,
      application_id: 12346,
      application_name: 'EnglishCentral',
      school_year: 2017,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'demo',
      order_type_display: 'デモ',
      start_at: '2017-10-02T00:00:00+09:00',
      end_at: '2020-10-02T00:00:00+09:00',
      updated_at: '2017-12-02T00:00:00+09:00',
    },
    {
      id: 341738765,
      application_id: 12345,
      application_name: 'ClassiNOTE',
      school_year: 2018,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'normal',
      order_type_display: '受注',
      start_at: '2018-10-02T00:00:00+09:00',
      end_at: '2021-10-02T00:00:00+09:00',
      updated_at: '2018-12-02T00:00:00+09:00',
    },
    {
      id: 341738766,
      application_id: 12345,
      application_name: 'ClassiNOTE',
      school_year: 2019,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'normal',
      order_type_display: '受注',
      start_at: '2019-10-02T00:00:00+09:00',
      end_at: '2022-10-02T00:00:00+09:00',
      updated_at: '2019-12-02T00:00:00+09:00',
    },
    {
      id: 341738767,
      application_id: 12345,
      application_name: 'ClassiNOTE',
      school_year: 2020,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'normal',
      order_type_display: '受注',
      start_at: '2020-10-02T00:00:00+09:00',
      end_at: '2023-10-02T00:00:00+09:00',
      updated_at: '2020-12-02T00:00:00+09:00',
    },
    {
      id: 341738768,
      application_id: 12345,
      application_name: 'ClassiNOTE',
      school_year: 2021,
      student_limit: 1000,
      demo_student_limit: 100,
      order_type: 'normal',
      order_type_display: '受注',
      start_at: '2021-10-02T00:00:00+09:00',
      end_at: '2024-10-02T00:00:00+09:00',
      updated_at: '2021-12-02T00:00:00+09:00',
    },
  ],
};

/**
 * [admin] 連携サービス受注更新APIのモック
 */
export const ADMIN_APPLICATION_ORDERS_UPDATED = {
  id: 341738767,
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: string, length?: number): any {
    if (length === undefined) {
      return value;
    }

    if (value.length > length) {
      return `${value.substring(0, length)}…`;
    }
    return value;
  }
}

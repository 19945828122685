import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import * as clamp from 'lodash/clamp';
import * as range from 'lodash/range';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  /** 現在のページ */
  @Input()
  currentPage: number;

  @Output()
  currentPageChange = new EventEmitter<number>();

  /** 1ページあたりの件数 */
  @Input()
  perPage: number;

  /** 総件数 */
  @Input()
  totalCount: number;

  /** ボタンの表示数 */
  @Input()
  size = 4;

  /** 表示されているページ */
  activePages = [];

  /** 現在のページから戻ることができるか */
  get cannotMoveToPrev() {
    return this.currentPage === 1;
  }

  /** 現在のページから進むことができるか */
  get cannotMoveToNext() {
    return this.currentPage === this.lastPage;
  }

  /** 左側の省略記号を表示させるか */
  get visibleLeftGap() {
    return !(this.activePages.includes(1) || this.activePages[0] === 2);
  }

  /** 右側の省略記号を表示させるか */
  get visibleRightGap() {
    return !(this.activePages.includes(this.lastPage) || this.activePages[3] === this.lastPage - 1);
  }

  /** 最後のページ */
  get lastPage() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  /** 最初へ移動するボタンを表示させるか */
  get displayFirst() {
    return this.activePages.includes(1);
  }

  /** 最後へ移動するボタンを表示させるか */
  get displayLast() {
    return this.activePages.includes(this.lastPage);
  }

  ngOnChanges(changes: SimpleChanges) {
    const pages = range(1, this.lastPage + 1) as number[];
    const index = pages.findIndex((page) => page === this.currentPage);
    const start = clamp(index - Math.floor(this.size / 2), 0, this.lastPage - this.size);
    const end = clamp(index + Math.ceil(this.size / 2), this.size, this.lastPage);

    this.activePages = pages.slice(start, end);
  }

  pageMoveTo(amount: number) {
    this.currentPageChange.emit(this.currentPage + amount);
  }

  pageChangeToLast() {
    this.currentPageChange.emit(this.lastPage);
  }
}

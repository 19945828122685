/**
 * ユーザ情報を扱うサービス
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

import { User } from '../../domains';

import { environment } from '../../../environments/environment';

@Injectable()
export class MeService {
  static readonly meUrl = `${environment.classi_api_url}/me`;
  private user$: Observable<User> | null = null;

  constructor(private http: HttpClient) {}

  /**
   * GET
   * @param {boolean} isForced - ユーザー情報を強制再取得するか否か
   * @returns {Observable<User>}
   */
  getMe(isForced = false): Observable<User> {
    if (this.user$ && !isForced) {
      return this.user$;
    }

    return (this.user$ = this.http.get<User>(MeService.meUrl).pipe(publishLast(), refCount()));
  }
}

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * 連携サービス詳細 機能情報 APIモック
 * @type {[{subject: string; image_url: string; body: string} , {subject: string; image_url: string; body: string} , {subject: string; image_url: string; body: string}]}
 */
export const APPLICATION_FUNCTIONS = [
  {
    subject: '機能①　ワークシート',
    image_url: 'https://cdn.classi.jp/partner/applications/8/functional_introductions/1.png',
    body: '授業ですぐ使えるワークシートがついています。',
  },
  {
    subject: '機能②　教材管理',
    image_url: 'https://cdn.classi.jp/partner/applications/8/functional_introductions/2.png',
    body: '生徒がログインして閲覧・ダウンロードできる教材を先生が管理、制御ができます。',
  },
  {
    subject: '機能③　教材データ',
    image_url: 'https://cdn.classi.jp/partner/applications/8/functional_introductions/3.png',
    body: 'ニュースの英文和文のテキストデータや、音声・動画データなど、先生で加工ができるデータもご用意。',
  },
];

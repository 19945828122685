<app-header [user]="user" [isError]="isError" [hidden]="isClassiNative"></app-header>
<div class="spen-ly-wrapper">
  <main class="prod-ly-main">
    <div id="container">
      <div id="wrap">
        <ng-content></ng-content>
      </div>
    </div>
  </main>
</div>
<app-footer [user]="user" [hidden]="isClassiNative"></app-footer>

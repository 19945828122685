/**
 * 先生・生徒 利用中連携サービス一覧を扱うサービス
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { OauthApplication, UserTypeId } from '../../domains';

import { MeService } from './me.service';

@Injectable()
export class UsingApplicationsService {
  static readonly applicationsUrlMap = {
    [UserTypeId.Teacher]: `${environment.classi_api_url}/teacher/applications`,
    [UserTypeId.Student]: `${environment.classi_api_url}/student/applications`,
  };

  constructor(
    private http: HttpClient,
    private meService: MeService,
  ) {}

  // GET
  getApplications(): Observable<OauthApplication[]> {
    // ユーザータイプを見てリクエストするAPIを分ける
    return this.meService.getMe().pipe(
      mergeMap((user) => {
        const { user_type_id } = user;

        if (user_type_id === UserTypeId.Teacher || user_type_id === UserTypeId.Student) {
          return this.http.get<OauthApplication[]>(UsingApplicationsService.applicationsUrlMap[user_type_id], {
            params: new HttpParams().set('os', 'web'),
          });
        }

        return of([]);
      }),
    );
  }

  getSettableApplications(): Observable<OauthApplication[]> {
    return this.meService.getMe().pipe(
      mergeMap((user) => {
        const { user_type_id } = user;

        if (user_type_id === UserTypeId.Teacher) {
          return this.http.get<OauthApplication[]>(UsingApplicationsService.applicationsUrlMap[user_type_id], {
            params: new HttpParams().set('settable', '1'),
          });
        }

        return of([]);
      }),
    );
  }
}

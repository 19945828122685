import { GetSettingsApplicationStatusResponse } from '../../../domains';

export const APPLICATION_STATUS: { [applicationId: number]: GetSettingsApplicationStatusResponse } = {
  12345: {
    id: 1,
    name: 'EnglishCentral',
    student_limit: 100,
    demo_student_limit: 10,
    total_student_limit: 100 + 10,
    student_count: 60,
    last_updated_user_name: '倉石 太郎 先生',
    last_updated_at: '2011-11-11T11:11:00+09:00',
  },

  12346: {
    id: 2,
    name: 'CNN ENGLISH EXPRESS',
    student_limit: 180,
    demo_student_limit: 10,
    total_student_limit: 180 + 10,
    student_count: 178,
    last_updated_user_name: '倉石 次郎 先生',
    last_updated_at: '2022-02-02T02:02:00+09:00',
  },

  12347: {
    id: 3,
    name: 'Monaca',
    student_limit: 40,
    demo_student_limit: 10,
    total_student_limit: 40 + 10,
    student_count: 40,
    last_updated_user_name: '倉石 三郎 先生',
    last_updated_at: '2033-03-03T03:03:00+09:00',
  },
};

/**
 * [admin] ユーザ情報 ユーザーロール
 */
export enum AdminUserRole {
  // 管理者
  Administrator = 1,

  // 一般
  General = 2,
}

import { GetClassroomsResponse } from '../../../domains/get-classrooms-response';

export const CLASSROOMS: GetClassroomsResponse = [
  {
    id: 1,
    name: '1組',
  },
  {
    id: 2,
    name: '2組',
  },
  {
    id: 3,
    name: '3組',
  },
  {
    id: 4,
    name: '4組',
  },
];

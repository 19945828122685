import { GetSchoolYearsResponse } from '../../../domains/get-school-years-response';

export const SCHOOL_YEARS: GetSchoolYearsResponse = [
  {
    id: 1,
    school_year: 2015,
  },
  {
    id: 2,
    school_year: 2016,
  },
  {
    id: 3,
    school_year: 2017,
  },
];

/**
 * [admin] 連携サービス導入事例APIのモック
 */

export const ADMIN_APPLICATION_CASE_STUDIES = [
  {
    id: 1,
    application_id: 12345,
    name: 'Classi高等学校',
    image_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/10/case_studies/8.jpg',
    application_case_study_elements: [
      {
        id: 1,
        application_case_study_id: 1,
        subject: '①週末課題での活用',
        body: '課題プリントとして配布し、授業で教材にでてくる単語を確認し、音源ファイルを生徒に配布。',
      },
      {
        id: 2,
        application_case_study_id: 1,
        subject: '②自学習',
        body: '生徒がWebサイトにログインして学習することができるので教員がニュースを選び定期考査の出題範囲にし自学習でも活用しています。',
      },
    ],
  },
  {
    id: 2,
    application_id: 12345,
    name: '事例2',
    image_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/10/case_studies/8.jpg',
    application_case_study_elements: [
      {
        id: 3,
        application_case_study_id: 2,
        subject: '①週末課題での活用',
        body: '課題プリントとして配布し、授業で教材にでてくる単語を確認し、音源ファイルを生徒に配布。',
      },
      {
        id: 4,
        application_case_study_id: 2,
        subject: '②自学習',
        body: '生徒がWebサイトにログインして学習することができるので教員がニュースを選び定期考査の出題範囲にし自学習でも活用しています。',
      },
    ],
  },
];

export const ADMIN_APPLICATION_CASE_STUDY_TEMPLATE = {
  id: null,
  application_id: 12345,
  name: 'Classi校',
  order: 4,
  image_url: 'assets/img/dummy.png',
  application_case_study_elements: [
    {
      id: 1,
      application_case_study_id: 1,
      subject: '①週末課題での活用',
      body: '課題プリントとして配布し、授業で教材にでてくる単語を確認し、音源ファイルを生徒に配布。',
    },
  ],
  created_at: '2016-07-27 16:05:47',
  updated_at: '2017-04-28 14:34:48',
};

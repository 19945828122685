import { GetGroupsResponse } from '../../../domains/get-groups-response';

export const GROUPS: { [schoolYear: number]: GetGroupsResponse } = {
  2015: [
    {
      id: 1,
      group_name: 'サッカー部',
    },
    {
      id: 2,
      group_name: 'ラグビー部',
    },
  ],
  2016: [
    {
      id: 3,
      group_name: 'バスケットボール部',
    },
    {
      id: 4,
      group_name: 'ハンドボール部',
    },
  ],
  2017: [
    {
      id: 5,
      group_name: 'テニス部',
    },
    {
      id: 6,
      group_name: '卓球部',
    },
    {
      id: 7,
      group_name: '15文字のラベルほげぴよほげぴ',
    },
    {
      id: 8,
      group_name: '16文字のラベルほげぴよほげぴよ',
    },
  ],
};

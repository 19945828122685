/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * 生徒 利用中連携サービス一覧 APIモック
 */
export const STUDENT_APPLICATIONS = [
  {
    id: 12345,
    name: 'EnglishCentral',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'アダプティブ・ラーニング支援ツール',
    partnership_type: 'sales',
  },
  {
    id: 12346,
    name: 'CNN ENGLISH EXPRESS',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/9/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: '英語学習アプリ',
    partnership_type: 'contents',
  },
];

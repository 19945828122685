import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface ErrorPayload {
  title: string;
  message: string;
}

@Injectable()
export class ErrorService {
  errorPayload = new BehaviorSubject<ErrorPayload | null>(null);

  constructor(private readonly router: Router) {}

  /**
   * エラーページにリダイレクトする
   * @param error - エラー情報
   */
  redirectToErrorPage(error: ErrorPayload) {
    this.errorPayload.next(error);
    this.router.navigate(['/error']);
  }

  /** 403 ページへリダイレクトさせる */
  redirectTo403() {
    this.router.navigate(['/403']);
  }

  /** 404 ページへリダイレクトさせる */
  redirectTo404() {
    this.router.navigate(['/404']);
  }
}

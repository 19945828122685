import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalContext } from '../modal-context';

@Injectable()
export class ModalService {
  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {}

  /**
   * コンポーネントを指定してモーダルを開く
   * @param component - モーダルのコンテンツとするコンポーネント
   * @param options - モーダルのオプション
   */
  open<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: Type<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: { injector?: Injector; data?: any } = {},
  ): {
    /** モーダルが閉じられたことを通知する Observable */
    afterClosed$: Observable<T>;
  } {
    const overlay = this.overlay.create();
    const afterClosed = new Subject<T>();
    const afterClosed$ = afterClosed.asObservable();

    // モーダルごとに ModalContext の内容を変える必要があるのでダイナミックに Injector を作る
    overlay.attach(
      new ComponentPortal(
        component,
        null,
        Injector.create(
          [
            {
              provide: ModalContext,
              useValue: {
                data: options.data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                close(value?: any) {
                  overlay.dispose();
                  afterClosed.next(value);
                  afterClosed.complete();
                },
              },
            },
          ],
          options.injector || this.injector,
        ),
      ),
    );

    return { afterClosed$ };
  }
}

import { GetSchoolStagesResponse } from '../../../domains/get-school-stages-response';

export const SCHOOL_STAGES: GetSchoolStagesResponse = [
  {
    id: 1,
    name: '中学校',
  },
  {
    id: 2,
    name: '高等学校',
  },
];

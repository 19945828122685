import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './core/components/error-page/error-page.component';
import { ForbiddenPageComponent } from './core/components/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/apps', pathMatch: 'full' },
  // eslint-disable-next-line max-len
  {
    path: 'apps',
    loadChildren: () => import('./applications/applications.module').then((m) => m.ApplicationsModule),
    data: { title: '連携サービス' },
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    data: { title: '連携サービス' },
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule), data: { title: '連携サービス 受注' } },
  {
    path: 'contents',
    loadChildren: () => import('./contents/contents.module').then((m) => m.ContentsModule),
    data: { title: '連携サービス' },
  },
  { path: '403', component: ForbiddenPageComponent, data: { title: 'エラー' } },
  { path: '404', component: NotFoundPageComponent, data: { title: 'エラー' } },
  { path: 'error', component: ErrorPageComponent, data: { title: 'エラー' } },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * 連携サービス詳細 機能情報 APIモック
 */
export const ADMIN_APPLICATION_FUNCTIONS = [
  {
    id: 1,
    application_id: 12345,
    order: 1,
    subject: '機能①　ワークシート',
    image_url: 'assets/img/dummy.png',
    body: '授業ですぐ使えるワークシートがついています。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
  {
    id: 2,
    application_id: 12345,
    order: 2,
    subject: '機能②　教材管理',
    image_url: 'assets/img/dummy.png',
    body: '生徒がログインして閲覧・ダウンロードできる教材を先生が管理、制御ができます。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
  {
    id: 3,
    application_id: 12345,
    order: 3,
    subject: '機能③　教材データ',
    image_url: 'assets/img/dummy.png',
    body: 'ニュースの英文和文のテキストデータや、音声・動画データなど、先生で加工ができるデータもご用意。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
  {
    id: 4,
    application_id: 12346,
    order: 1,
    subject: '機能①　ワークシート',
    image_url: 'assets/img/dummy.png',
    body: '授業ですぐ使えるワークシートがついています。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
  {
    id: 5,
    application_id: 12346,
    order: 2,
    subject: '機能②　教材管理',
    image_url: 'assets/img/dummy.png',
    body: '生徒がログインして閲覧・ダウンロードできる教材を先生が管理、制御ができます。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
  {
    id: 6,
    application_id: 12346,
    order: 3,
    subject: '機能③　教材データ',
    image_url: 'assets/img/dummy.png',
    body: 'ニュースの英文和文のテキストデータや、音声・動画データなど、先生で加工ができるデータもご用意。',
    created_at: '2018-04-01T00:00:00+09:00',
    updated_at: '2018-04-01T00:00:00+09:00',
  },
];

export const ADMIN_APPLICATION_FUNCTION_TEMPLATE = {
  id: null,
  application_id: 12345,
  order: 4,
  subject: '新規機能',
  body: '新規機能説明',
  image_url: 'assets/img/dummy.png',
  created_at: '2016-07-27 16:05:47',
  updated_at: '2017-04-28 14:34:48',
};

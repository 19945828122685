export const ADMIN_APPLICATION_ORDER_DETAIL = {
  application_order_id: 13459,
  start_at: '2019-04-01 00:00:00',
  end_at: '2020-04-30 00:00:00',
  school_id: 1234,
  school_name: '新宿高等学校',
  school_year: 2019,
  application_id: 9,
  application_name: 'EnglishCentral',
  student_limit: 100,
  demo_student_limit: 10,
  total_student_limit: 100 + 10,
  order_type_display: '受注',
  memo: '今年だけ',
  registered_students_total: 60,
  classes: [
    {
      school_stage_id: 1,
      school_stage_name: '高校',
      grade_id: 4,
      grade_name: '1年',
      classroom_id: 200,
      classroom_name: '1組',
      registered_students_count: 30,
      students_count: 30,
    },
    {
      school_stage_id: 1,
      school_stage_name: '高校',
      grade_id: 4,
      grade_name: '1年',
      classroom_id: 201,
      classroom_name: '2組',
      registered_students_count: 30,
      students_count: 30,
    },
  ],
};

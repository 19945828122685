/* eslint-disable max-len, @typescript-eslint/quotes */
import { AdminUserRole } from '../../../domains/admin-user-role';

/**
 * [admin] ユーザ情報取得APIモック
 * @type {{id: number; name: string; user_role: AdminUserRole; last_login: string}}
 */
export const ADMIN_ME = {
  id: 12345,
  name: '山田 太郎',
  user_role: AdminUserRole.Administrator,
  last_login: '2018-01-18T12:12:11+09:00',
};

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * [admin] 連携サービス画像・動画APIのモック
 */

export const ADMIN_APPLICATION_IMAGES = [
  {
    id: 1,
    application_id: 12345,
    order: 1,
    image_type: 'photo',
    url: 'https://cdn.classi.jp/partner/applications/3/images/2.png',
    created_at: '2016-07-27 16:05:47',
    updated_at: '2017-04-28 14:34:48',
  },
  {
    id: 2,
    application_id: 12345,
    order: 2,
    image_type: 'photo',
    url: 'https://cdn.classi.jp/partner/applications/3/images/2.png',
    created_at: '2016-07-27 16:05:47',
    updated_at: '2017-04-28 14:34:48',
  },
  {
    id: 3,
    application_id: 12345,
    order: 3,
    image_type: 'video',
    url: 'https://www.youtube.com/embed/GK05MBjg5yc',
    created_at: '2016-07-27 16:05:47',
    updated_at: '2017-04-28 14:34:48',
  },
];

export const ADMIN_APPLICATION_IMAGE_TEMPLATE = {
  id: null,
  application_id: 12345,
  order: 4,
  image_type: 'photo',
  url: 'assets/img/dummy.png',
  created_at: '2016-07-27 16:05:47',
  updated_at: '2017-04-28 14:34:48',
};

/* eslint-disable max-lines */
import { InMemoryDbService, ParsedRequestUrl, RequestInfo, RequestInfoUtilities, ResponseOptions, STATUS } from 'angular-in-memory-web-api';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ADMIN_APPLICATION_CASE_STUDIES, ADMIN_APPLICATION_CASE_STUDY_TEMPLATE } from './mocks/mock-admin-application-case-studies';
import { ADMIN_APPLICATION_CATEGORIES } from './mocks/mock-admin-application-categories';
import { ADMIN_APPLICATION_FUNCTIONS, ADMIN_APPLICATION_FUNCTION_TEMPLATE } from './mocks/mock-admin-application-functions';
import { ADMIN_APPLICATION_IMAGES, ADMIN_APPLICATION_IMAGE_TEMPLATE } from './mocks/mock-admin-application-images';
import { ADMIN_APPLICATION_ORDER_DETAIL } from './mocks/mock-admin-application-order-detail';
import { ADMIN_APPLICATION_ORDERS, ADMIN_APPLICATION_ORDERS_UPDATED } from './mocks/mock-admin-application-orders';
import { ADMIN_APPLICATION_ORDERS_SCHOOLS, ordersSchoolsPredicate } from './mocks/mock-admin-application-orders-schools';
import { ADMIN_APPLICATION_USER_TYPES, ADMIN_APPLICATION_USER_TYPE_TEMPLATE } from './mocks/mock-admin-application-user-types';
import { ADMIN_APPLICATIONS, ADMIN_APPLICATION_TEMPLATE } from './mocks/mock-admin-applications';
import { ADMIN_DEVELOPERS } from './mocks/mock-admin-developers';
import { ADMIN_ME } from './mocks/mock-admin-me';
import { ADMIN_SETTING_HISTORY } from './mocks/mock-admin-setting-history';
import { ALL_APPLICATION } from './mocks/mock-all-applications';
import { APPLICATION_CASE_STUDIES } from './mocks/mock-application-case-studies';
import { APPLICATION_STATUS } from './mocks/mock-application-status';
import { APPLICATIONS } from './mocks/mock-applications';
import { APPLICATION_FUNCTIONS } from './mocks/mock-applications-functions';
import { CLASSROOMS } from './mocks/mock-classrooms';
import { CONTENTS } from './mocks/mock-contents';
import { GA4_USER } from './mocks/mock-ga4-user';
import { GRADES } from './mocks/mock-grades';
import { GROUPS } from './mocks/mock-groups';
import { ME } from './mocks/mock-me';
import { MENU } from './mocks/mock-menu';
import { SAMPLE_CONTENTS } from './mocks/mock-sample_contents';
import { SCHOOL_STAGES } from './mocks/mock-school-stages';
import { SCHOOL_YEARS } from './mocks/mock-school-years';
import { STUDENT_APPLICATIONS } from './mocks/mock-student-applications';
import { predicate, STUDENTS } from './mocks/mock-students';
import { TEACHER_APPLICATIONS } from './mocks/mock-teacher-applications';

class MockResponse implements ResponseOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create200(body: any) {
    return () => ({
      body,
      status: STATUS.OK,
    });
  }

  static create400() {
    return () => ({
      status: STATUS.BAD_REQUEST,
      error: {
        title: 'システムエラーです',
        message: 'リクエストは不正です',
      },
    });
  }

  static create404() {
    return () => ({
      status: STATUS.NOT_FOUND,
      error: {
        title: 'システムエラーです',
        message: 'リソースは見つかりません',
      },
    });
  }
}

export class InMemoryDataService implements InMemoryDbService {
  // コンテキストパス: { url: データ} の形式
  // e.g. /classi/api/me にアクセスしたいときは classi: {me: data} と記載
  readonly API = {
    classi: {
      me: ME, // ユーザ情報取得
      ga4_user: GA4_USER,
      menu: MENU, // メニュー情報取得
      teacher_applications: TEACHER_APPLICATIONS, // 先生 （利用中連携サービス一覧）
      student_applications: STUDENT_APPLICATIONS, // 生徒 （利用中連携サービス一覧）
      applications: APPLICATIONS,
      application_functions: APPLICATION_FUNCTIONS, // 連携サービス詳細 機能情報
      application_case_studies: APPLICATION_CASE_STUDIES, // 連携サービス詳細 事例情報

      // 連携サービス利用設定 共通
      schoolyears: SCHOOL_YEARS,
      schoolstages: SCHOOL_STAGES,
      grades: GRADES,
      classrooms: CLASSROOMS,
      groups: GROUPS,
    },
    admin: {
      me: ADMIN_ME, // [admin] ユーザ情報取得
      application_orders: ADMIN_APPLICATION_ORDERS, // [admin] 連携サービス受注一覧・登録・更新
      application_orders_schools: ADMIN_APPLICATION_ORDERS_SCHOOLS, // [admin] 連携サービス受注学校一覧
      applications: ADMIN_APPLICATIONS, // [admin] 連携サービス一覧
      application_functional_introductions: ADMIN_APPLICATION_FUNCTIONS, // [admin] 連携サービス機能紹介
      application_images: ADMIN_APPLICATION_IMAGES, // [admin] 連携サービス画像一覧
      application_user_types: ADMIN_APPLICATION_USER_TYPES, // [admin] 連携サービスユーザー種別一覧
      application_case_studies: ADMIN_APPLICATION_CASE_STUDIES, // [admin] 導入事例
      developers: ADMIN_DEVELOPERS, // [admin] ディベロッパー一覧
      application_categories: ADMIN_APPLICATION_CATEGORIES, // [admin] 連携サービスカテゴリー一覧
    },
    contents: CONTENTS,
    sample_contents: SAMPLE_CONTENTS,
  };

  readonly api = {};

  constructor() {
    Object.keys(this.API).forEach((k) => {
      Object.keys(this.API[k]).forEach((v) => {
        this.api[`${k}_${v}`] = this.API[k][v];
      });
    });
  }

  /**
   * すべてのGETリクエストをインターセプトする
   * - undefined を返すと通常の処理が行われる
   * @param reqInfo - リクエスト情報
   */
  // eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
  get(reqInfo: RequestInfo): Observable<any> | void {
    if (reqInfo.collectionName.startsWith('classi_grades')) {
      return this.getGrades(reqInfo);
    }

    if (reqInfo.collectionName.startsWith('classi_groups')) {
      return this.getGroups(reqInfo);
    }

    if (/^\/classi\/api\/settings\/applications\/(\w+)\/students/.test(reqInfo.url)) {
      return this.getStudents(parseInt(RegExp.$1, 10), reqInfo);
    }

    if (/^\/classi\/api\/settings\/applications\/(\w+)\/status/.test(reqInfo.url)) {
      return this.getStatus(parseInt(RegExp.$1, 10), reqInfo);
    }

    // ファイルダウンロードAPIのエンドポイント
    if (/^\/classi\/api\/settings\/student_order/.test(reqInfo.url)) {
      return this.getDownload(reqInfo);
    }

    if (/^\/admin\/api\/application_functional_introductions/.test(reqInfo.url)) {
      return this.getAdminApplicationFunctions(reqInfo);
    }
    if (/^\/admin\/api\/application_orders_schools\/(\w+)/.test(reqInfo.url)) {
      return this.getAdminApplicationOrderDetail(parseInt(RegExp.$1, 10), reqInfo);
    }
    if (/^\/admin\/api\/application_orders_schools/.test(reqInfo.url)) {
      return this.getAdminApplicationOrdersSchools(reqInfo);
    }
    if (/^\/admin\/api\/setting_history\/(\w+)/.test(reqInfo.url)) {
      return this.getAdminSettingHistory(parseInt(RegExp.$1, 10), reqInfo);
    }
    if (/^\/classi\/api\/contents/.test(reqInfo.url)) {
      return this.getContent(reqInfo);
    }
    if (/^\/classi\/api\/sample_contents/.test(reqInfo.url)) {
      return this.getSampleContents(reqInfo);
    }

    if (reqInfo.url.startsWith('/admin/api/application_images')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_IMAGES));
    }

    if (reqInfo.url.startsWith('/admin/api/application_user_types')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_USER_TYPES));
    }

    if (reqInfo.url.startsWith('/admin/api/application_orders')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_ORDERS));
    }

    if (reqInfo.url === '/classi/api/applications') {
      return reqInfo.utils.createResponse$(MockResponse.create200(ALL_APPLICATION));
    }

    if (reqInfo.url.startsWith('/admin/api/application_case_studies')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(APPLICATION_CASE_STUDIES));
    }

    if (reqInfo.url.startsWith('/classi/api/teacher/applications')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(TEACHER_APPLICATIONS));
    }

    if (reqInfo.url.startsWith('/classi/api/classrooms')) {
      return reqInfo.utils.createResponse$(MockResponse.create200(CLASSROOMS));
    }
  }

  /**
   * すべての POST リクエストのインターセプター
   * - undefined を返すと通常の処理が行われる
   * @param reqInfo - リクエスト情報
   */
  // eslint-disable-next-line complexity
  post(reqInfo: RequestInfo) {
    // ファイルアップロードAPIのエンドポイント
    if (/^\/classi\/api\/settings\/student_order/.test(reqInfo.url)) {
      return this.postUpload(reqInfo);
    }

    // [admin] 連携サービス受注登録APIのエンドポイント
    if (/^\/admin\/api\/application_orders/.test(reqInfo.url)) {
      return this.postApplicationOrders(reqInfo);
    }

    // [admin] 連携サービス登録APIのエンドポイント
    if (/^\/admin\/api\/applications/.test(reqInfo.url)) {
      return this.postAndPutApplication(reqInfo);
    }

    // [admin] 連携サービス画像・動画登録APIのエンドポイント
    if (/^\/admin\/api\/application_images/.test(reqInfo.url)) {
      return this.postAndPutApplicationImage(reqInfo);
    }

    // [admin] 連携サービス導入事例登録APIのエンドポイント
    if (/^\/admin\/api\/application_case_studies/.test(reqInfo.url)) {
      return this.postAndPutApplicationCaseStudy(reqInfo);
    }

    // [admin] 連携サービス機能紹介登録APIのエンドポイント
    if (/^\/admin\/api\/application_functional_introductions/.test(reqInfo.url)) {
      return this.postAndPutApplicationFunction(reqInfo);
    }
  }

  /**
   * すべての PUT リクエストのインターセプター
   * - undefined を返すと通常の処理が行われる
   * @param reqInfo - リクエスト情報
   */
  // eslint-disable-next-line complexity
  put(reqInfo: RequestInfo) {
    // [admin] 連携サービス受注更新APIのエンドポイント
    if (/^\/admin\/api\/application_orders/.test(reqInfo.url)) {
      return this.putApplicationOrders(reqInfo);
    }

    // [admin] 連携サービス更新APIのエンドポイント
    if (/^\/admin\/api\/applications/.test(reqInfo.url)) {
      return this.postAndPutApplication(reqInfo);
    }

    // [admin] 連携サービス認証URL更新APIのエンドポイント
    if (/^\/admin\/api\/application_user_types/.test(reqInfo.url)) {
      return this.putApplicationUserType(reqInfo);
    }

    // [admin] 連携サービス画像・動画更新APIのエンドポイント
    if (/^\/admin\/api\/application_images/.test(reqInfo.url)) {
      return this.postAndPutApplicationImage(reqInfo);
    }

    // [admin] 連携サービス導入事例更新APIのエンドポイント
    if (/^\/admin\/api\/application_case_studies/.test(reqInfo.url)) {
      return this.postAndPutApplicationCaseStudy(reqInfo);
    }

    // [admin] 連携サービス機能紹介更新APIのエンドポイント
    if (/^\/admin\/api\/application_functional_introductions/.test(reqInfo.url)) {
      return this.postAndPutApplicationFunction(reqInfo);
    }
  }

  createDb() {
    return this.api;
  }

  // eslint-disable-next-line complexity
  parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl {
    if (
      // 先生・生徒 利用中連携サービス一覧はURLのネストを書き換える
      url.includes('/teacher/applications') ||
      url.includes('/student/applications')
    ) {
      url = url.replace(/\/applications/, '_applications');
    } else if (
      // 機能情報・事例情報はURLのネストを書き換える
      url.includes('/applications') &&
      (url.includes('/functions') || url.includes('/case_studies'))
    ) {
      url = url.replace(/\/applications\/\d+\/(functions|case_studies)/, '/application_$1');
    }

    const newUrl = utils.parseRequestUrl(url);
    const parsed = url.split('/');
    newUrl.apiBase = `/${parsed[1]}/${parsed[2]}`;
    newUrl.collectionName = `${parsed[1]}_${parsed[3]}`;
    newUrl.id = parsed[4];
    // see https://github.com/angular/in-memory-web-api/blob/956637e25aa6347973e41d6490a529f186fe706c/src/in-mem/backend.service.ts#L575
    newUrl.resourceUrl = `${newUrl.apiBase}/${newUrl.collectionName}/`;
    return newUrl;
  }

  private getAdminApplicationOrderDetail(id: number, reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_ORDER_DETAIL));
  }

  private getAdminSettingHistory(id: number, reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_SETTING_HISTORY));
  }

  /**
   * 連携サービス利用状況取得APIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private getStatus(id: number, reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(MockResponse.create200(APPLICATION_STATUS[id]));
  }

  /**
   * 生徒一覧取得APIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private getStudents(id: number, reqInfo: RequestInfo) {
    const query = this.getQueryFromReqInfo(reqInfo);
    const page = query.has('page') ? +query.get('page')[0] : 1;
    const perPage = 20;
    const offset = perPage * (page - 1);
    const student = STUDENTS.filter(predicate(query));

    return reqInfo.utils.createResponse$(
      MockResponse.create200({
        total_pages: Math.ceil(student.length / perPage),
        current_page: page,
        per_page: perPage,
        total_count: student.length,
        students: student.slice(offset, offset + perPage),
      }),
    );
  }

  /**
   * 学年リスト取得APIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getGrades(reqInfo: RequestInfo): Observable<any> | void {
    const query = this.getQueryFromReqInfo(reqInfo);

    if (!query.has('school_stage_id')) {
      return reqInfo.utils.createResponse$(MockResponse.create400());
    }

    const id = query.get('school_stage_id')[0];
    const body = GRADES[id];

    if (body) {
      return reqInfo.utils.createResponse$(MockResponse.create200(body));
    }

    return reqInfo.utils.createResponse$(MockResponse.create404());
  }

  /**
   * グループリスト取得APIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private getGroups(reqInfo: RequestInfo) {
    const query = this.getQueryFromReqInfo(reqInfo);

    if (!query.has('school_year')) {
      return reqInfo.utils.createResponse$(MockResponse.create400());
    }

    const year = query.get('school_year')[0];
    const body = GROUPS[year];

    if (body) {
      return reqInfo.utils.createResponse$(MockResponse.create200(body));
    }

    return reqInfo.utils.createResponse$(MockResponse.create404());
  }

  /**
   * ファイルダウンロードAPIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private getDownload(reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(MockResponse.create200(new Blob(['hello'], { type: 'text/plain' })));
  }

  /**
   * ファイルアップロードAPIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private postUpload(reqInfo: RequestInfo) {
    return reqInfo.utils.createResponse$(MockResponse.create200('')).pipe(delay(4000));
  }

  /**
   * [admin] 連携サービス受注登録APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private postApplicationOrders(reqInfo: RequestInfo) {
    const requestBody = reqInfo.utils.getJsonBody(reqInfo.req);
    const studentLimit = requestBody.student_limit;

    if (studentLimit === 0) {
      return reqInfo.utils.createResponse$(MockResponse.create400());
    }

    return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_ORDERS_UPDATED));
  }

  /**
   * [admin] 連携サービス受注更新APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private putApplicationOrders(reqInfo: RequestInfo) {
    const requestBody = reqInfo.utils.getJsonBody(reqInfo.req);
    const studentLimit = requestBody.student_limit;

    if (studentLimit === 0) {
      return reqInfo.utils.createResponse$(MockResponse.create400());
    }

    return reqInfo.utils.createResponse$(MockResponse.create200(ADMIN_APPLICATION_ORDERS_UPDATED));
  }

  /**
   * [admin] 連携サービス機能紹介取得APIのインターセプター
   * @param reqInfo - リクエスト情報
   */
  private getAdminApplicationFunctions(reqInfo: RequestInfo) {
    const query = this.getQueryFromReqInfo(reqInfo);

    if (!query.has('application_id')) {
      return reqInfo.utils.createResponse$(MockResponse.create404());
    }

    const applicationId = Number(query.get('application_id')[0]);
    const body = ADMIN_APPLICATION_FUNCTIONS.filter((data) => {
      return data.application_id === applicationId;
    });

    if (body) {
      return reqInfo.utils.createResponse$(MockResponse.create200(body));
    }

    return reqInfo.utils.createResponse$(MockResponse.create404());
  }

  /**
   * [admin] 連携サービス登録・更新APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private postAndPutApplication(reqInfo: RequestInfo) {
    // 新規登録の場合現在時刻からidを作成
    const id = reqInfo.id || new Date().getTime();
    const response = {
      ...ADMIN_APPLICATION_TEMPLATE,
      id,
    };
    return reqInfo.utils.createResponse$(MockResponse.create200(response));
  }

  /**
   * [admin] 連携サービス認証URL更新APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private putApplicationUserType(reqInfo: RequestInfo) {
    const response = ADMIN_APPLICATION_USER_TYPE_TEMPLATE;
    response.id = reqInfo.id;
    response.user_type_id = reqInfo.id;
    return reqInfo.utils.createResponse$(MockResponse.create200(response));
  }

  /**
   * [admin] 連携サービス画像・動画登録・更新APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private postAndPutApplicationImage(reqInfo: RequestInfo) {
    // 新規登録の場合現在時刻からidを作成
    const id = reqInfo.id || new Date().getTime();
    const response = Object.assign(ADMIN_APPLICATION_IMAGE_TEMPLATE, { id });

    return reqInfo.utils.createResponse$(MockResponse.create200(response));
  }

  private postAndPutApplicationCaseStudy(reqInfo: RequestInfo) {
    // 新規登録の場合現在時刻からidを作成
    const id = reqInfo.id || new Date().getTime();
    const response = Object.assign(ADMIN_APPLICATION_CASE_STUDY_TEMPLATE, { id });
    return reqInfo.utils.createResponse$(MockResponse.create200(response));
  }

  /**
   * [admin] 連携サービス機能紹介登録・更新APIのインターセプター
   * @param {RequestInfo} reqInfo
   */
  private postAndPutApplicationFunction(reqInfo: RequestInfo) {
    // 新規登録の場合現在時刻からidを作成
    const id = reqInfo.id || new Date().getTime();
    const response = Object.assign(ADMIN_APPLICATION_FUNCTION_TEMPLATE, { id });
    return reqInfo.utils.createResponse$(MockResponse.create200(response));
  }

  /**
   * RequestInfo オブジェクトからリクエスト時のクエリーパラメーターを抜き出す
   * @param reqInfo - リクエスト情報
   */
  private getQueryFromReqInfo(reqInfo: RequestInfo): Map<string, string[]> {
    const map = new Map<string, string[]>();
    reqInfo.query.forEach((values, key) => {
      map.set(key, values);
    });
    return map;
  }

  private getContent(reqInfo: RequestInfo) {
    const params = this.getQueryFromReqInfo(reqInfo);
    if (!params.has('application_id')) {
      return reqInfo.utils.createResponse$(MockResponse.create404());
    }
    return reqInfo.utils.createResponse$(MockResponse.create200(CONTENTS));
  }

  private getSampleContents(reqInfo: RequestInfo) {
    const params = this.getQueryFromReqInfo(reqInfo);
    if (!params.has('application_id')) {
      return reqInfo.utils.createResponse$(MockResponse.create404());
    }
    return reqInfo.utils.createResponse$(MockResponse.create200(SAMPLE_CONTENTS));
  }

  private getAdminApplicationOrdersSchools(reqInfo: RequestInfo) {
    const query = this.getQueryFromReqInfo(reqInfo);

    if (!query.has('school_year')) {
      return reqInfo.utils.createResponse$(MockResponse.create404());
    }

    const ordersSchools = ADMIN_APPLICATION_ORDERS_SCHOOLS.filter(ordersSchoolsPredicate(query));

    return reqInfo.utils.createResponse$(
      MockResponse.create200({
        orders: ordersSchools,
        total: ordersSchools.length,
      }),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class ModalContext<T = any, U = any> {
  /** モーダルを開いた元のコンポーネントから渡されるデータ */
  data?: U;

  /**
   * モーダルを閉じる
   * @param value - モーダルから元のコンポーネントへ戻す値
   */
  abstract close(value?: T): void;
}

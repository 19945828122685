import * as range from 'lodash/range';
import { Grade, Group, Student } from '../../../domains';
import { CLASSROOMS as classrooms } from './mock-classrooms';
import { GRADES } from './mock-grades';
import { GROUPS } from './mock-groups';

import { SCHOOL_STAGES as schoolStages } from './mock-school-stages';

type Students = Student[];

const groups = Object.values(GROUPS).reduce((a: Group[], b: Group[]) => a.concat(b)) as Group[];
const grades = Object.values(GRADES).reduce((a: Grade[], b: Grade[]) => a.concat(b)) as Grade[];

const findFromCollection = <T extends { id: number }>(collection: T[], id: number): T | null => collection.find((item) => item.id === id);

interface GenMockStudentsArgs {
  /** 年度 */
  year: number;

  /** id のオフセット値 */
  offset: number;

  /** 校種 */
  school_stage_name: string;

  /** 学年 */
  grade_name: string;

  /** クラス */
  classroom_name: string;

  /** グループ名 */
  groups_name: string;
}

/**
 * 生徒のモックデータを作成する
 */
const genMockStudents = ({ year, offset, school_stage_name, grade_name, classroom_name, groups_name }: GenMockStudentsArgs): Student[] => {
  return (range(1, 41) as number[]).map((number) => {
    const id = offset + number;

    return {
      id: id,
      photo_uri: '/assets/img/icon_user_01.png',
      name: `倉井志 ${id}郎`,
      school_stage_name,
      grade_name,
      classroom_name,
      attendance_number: number,
      groups_name,
      year,
    };
  });
};

// eslint-disable-next-line complexity
export const predicate = (condition: Map<string, string[]>) => (student: Student) => {
  const schoolYear = +(condition.get('school_year') || [])[0];
  const name = (condition.get('name') || [])[0];
  const schoolStage = findFromCollection(schoolStages, +condition.get('school_stage_id'));
  const grade = findFromCollection(grades, +condition.get('grade_id'));
  const classroom = findFromCollection(classrooms, +condition.get('classroom_id'));
  const group = findFromCollection(groups, +condition.get('group_id'));

  if (schoolYear && student['year'] !== schoolYear) {
    return false;
  }

  if (schoolStage && student.school_stage_name !== schoolStage.name) {
    return false;
  }

  if (grade && student.grade_name !== grade.name) {
    return false;
  }

  if (classroom && student.classroom_name !== classroom.name) {
    return false;
  }

  if (group && student.groups_name !== group.group_name) {
    return false;
  }

  if (name && !student.name.includes(name)) {
    return false;
  }

  return true;
};

export const STUDENTS: Students = [
  /* eslint-disable max-len */
  ...genMockStudents({
    year: 2015,
    offset: 40 * 0,
    school_stage_name: '中学校',
    grade_name: '中学1年',
    classroom_name: '1組',
    groups_name: 'サッカー部',
  }),
  ...genMockStudents({
    year: 2015,
    offset: 40 * 1,
    school_stage_name: '中学校',
    grade_name: '中学1年',
    classroom_name: '2組',
    groups_name: 'ラグビー部',
  }),
  ...genMockStudents({
    year: 2015,
    offset: 40 * 2,
    school_stage_name: '中学校',
    grade_name: '中学1年',
    classroom_name: '3組',
    groups_name: 'バスケットボール部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 3,
    school_stage_name: '中学校',
    grade_name: '中学2年',
    classroom_name: '1組',
    groups_name: 'ハンドボール部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 4,
    school_stage_name: '中学校',
    grade_name: '中学2年',
    classroom_name: '2組',
    groups_name: 'テニス部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 5,
    school_stage_name: '中学校',
    grade_name: '中学2年',
    classroom_name: '3組',
    groups_name: '卓球部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 6,
    school_stage_name: '中学校',
    grade_name: '中学3年',
    classroom_name: '1組',
    groups_name: 'サッカー部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 7,
    school_stage_name: '中学校',
    grade_name: '中学3年',
    classroom_name: '2組',
    groups_name: 'ラグビー部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 8,
    school_stage_name: '中学校',
    grade_name: '中学3年',
    classroom_name: '3組',
    groups_name: 'バスケットボール部',
  }),

  ...genMockStudents({
    year: 2015,
    offset: 40 * 9,
    school_stage_name: '高等学校',
    grade_name: '高校1年',
    classroom_name: '1組',
    groups_name: 'ハンドボール部',
  }),
  ...genMockStudents({
    year: 2015,
    offset: 40 * 10,
    school_stage_name: '高等学校',
    grade_name: '高校1年',
    classroom_name: '2組',
    groups_name: 'テニス部',
  }),
  ...genMockStudents({
    year: 2015,
    offset: 40 * 11,
    school_stage_name: '高等学校',
    grade_name: '高校1年',
    classroom_name: '3組',
    groups_name: '卓球部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 12,
    school_stage_name: '高等学校',
    grade_name: '高校2年',
    classroom_name: '1組',
    groups_name: 'サッカー部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 13,
    school_stage_name: '高等学校',
    grade_name: '高校2年',
    classroom_name: '2組',
    groups_name: 'ラグビー部',
  }),
  ...genMockStudents({
    year: 2016,
    offset: 40 * 14,
    school_stage_name: '高等学校',
    grade_name: '高校2年',
    classroom_name: '3組',
    groups_name: 'バスケットボール部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 15,
    school_stage_name: '高等学校',
    grade_name: '高校3年',
    classroom_name: '1組',
    groups_name: 'ハンドボール部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 16,
    school_stage_name: '高等学校',
    grade_name: '高校3年',
    classroom_name: '2組',
    groups_name: 'テニス部',
  }),
  ...genMockStudents({
    year: 2017,
    offset: 40 * 17,
    school_stage_name: '高等学校',
    grade_name: '高校3年',
    classroom_name: '3組',
    groups_name: '卓球部',
  }),
  /* eslint-enable max-len */
];

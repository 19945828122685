/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * 提供中連携サービス一覧：詳細 APIモック
 */
export const ALL_APPLICATION = {
  primary: [
    {
      category_id: 1,
      category_name: '英語4技能',
      applications: [
        {
          id: 12345,
          name: 'EnglishCentral',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: 'アダプティブ・ラーニング支援ツール',
        },
        {
          id: 12346,
          name: 'CNN ENGLISH EXPRESS',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/9/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: '英語学習アプリ',
        },
        {
          id: 12347,
          name: 'Monaca Education',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: '無料でグローバルな動画とプロ講師からオンラインで英語を学びましょう。',
        },
        {
          id: 12348,
          name: 'QUEST EDUCATION SOCIALCHANGE', // 一番長い
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: 'Lorem ipsum dolor sit amet, ne soleat virtute feugait eos, dolor homero ignota ei vim.',
        },
        {
          id: 12349,
          name: 'FunGO',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: '英語スピーキングリスニング勉強に使える',
        },
      ],
    },
    {
      category_id: 2,
      category_name: 'アダプティブ・ラーニング',
      applications: [
        {
          id: 12345,
          name: 'EnglishCentral',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: 'アダプティブ・ラーニング支援ツール',
        },
        {
          id: 12346,
          name: 'CNN ENGLISH EXPRESS',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/9/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: '英語学習アプリ',
        },
        {
          id: 12347,
          name: 'Monaca Education',
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: '無料でグローバルな動画とプロ講師からオンラインで英語を学びましょう。',
        },
        {
          id: 12348,
          name: 'QUEST EDUCATION SOCIALCHANGE', // 一番長い
          logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
          web_authentication_url: 'https://xxxxxx/web/start',
          android_authentication_url: 'https://xxxxxx/android/start',
          ios_authentication_url: 'https://xxxxxx/ios/start',
          caption: 'Lorem ipsum dolor sit amet, ne soleat virtute feugait eos, dolor homero ignota ei vim.',
        },
      ],
    },
  ],
  classi_apps: [
    {
      id: 12348,
      name: '投票ボックス by Classi',
      logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
      web_authentication_url: 'https://xxxxxx/web/start',
      android_authentication_url: 'https://xxxxxx/android/start',
      ios_authentication_url: 'https://xxxxxx/ios/start',
      caption: 'Lorem ipsum dolor sit amet, ne soleat virtute feugait eos, dolor homero ignota ei vim.',
      manual_document_url: 'http://dummy',
    },
  ],
};

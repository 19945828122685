import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ClassiServiceComponent } from './components/classi-service/classi-service.component';
import { FooterComponent } from './components/footer/footer.component';
import { GlobalMenuComponent } from './components/global-menu/global-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { Ga4Directive } from './directives/ga4.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';

const COMPONENTS = [
  LoadingComponent,
  PaginationComponent,
  ClassiServiceComponent,
  HeaderComponent,
  GlobalMenuComponent,
  FooterComponent,
  Ga4Directive,
  EllipsisPipe,
  BackButtonComponent,
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [COMPONENTS],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, COMPONENTS],
})
export class SharedModule {}

import { ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SentryHandler } from './sentry.handler';

export class ErrorHandlerFactory {
  static create(): ErrorHandler {
    if (environment.sentry) {
      return new SentryHandler();
    }
    return new ErrorHandler();
  }
}

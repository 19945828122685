import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { User } from '../../../domains/index';
import { GlobalMenuComponent } from '../global-menu/global-menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title$: Observable<string>;

  @Input() user: User;
  @Input() isError = false;
  homeUrl = environment.home_url;
  helpUrl = environment.help_url;

  @ViewChild(GlobalMenuComponent) globalMenuComponent: GlobalMenuComponent;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    // TODO: ユニットテストの依存注入を修正する
    this.title$ = this.route.data.pipe(map((data) => data.title || ''));
  }

  openMenu(): void {
    this.globalMenuComponent.toggleMenu();
  }
}

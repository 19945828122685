import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { GetGa4UserResponse } from './domains';

@Injectable({
  providedIn: 'root',
})
export class Ga4DataService {
  private static readonly baseURL = environment.classi_api_url;

  constructor(private readonly http: HttpClient) {}

  getGa4User(): Observable<{ ga4_user: GetGa4UserResponse }> {
    return this.http.get<{ ga4_user: GetGa4UserResponse }>(`${Ga4DataService.baseURL}/ga4_user`);
  }
}

import { AdminApplicationOrderSchoolItem } from '../../../domains';

type OrdersSchools = AdminApplicationOrderSchoolItem[];

// eslint-disable-next-line complexity
export const ordersSchoolsPredicate = (condition: Map<string, string[]>) => (item: AdminApplicationOrderSchoolItem) => {
  const schoolYear = +(condition.get('school_year') || [])[0];
  const schoolName = (condition.get('school_name') || [])[0];
  const applicationId = +(condition.get('application_id') || [])[0];
  const orderType = +(condition.get('order_type') || [])[0];
  const memo = (condition.get('memo') || [])[0];

  const orderTypes = ['受注', '受注+デモ利用', 'デモ利用', 'デモ(基本サービス未受注)', '社内デモ'];

  if (schoolYear && item.school_year !== schoolYear) {
    return false;
  }

  if (schoolName && !item.school_name.includes(schoolName)) {
    return false;
  }

  if (applicationId && item.application_id !== applicationId) {
    return false;
  }

  if (orderType && item.order_type_display !== orderTypes[orderType]) {
    return false;
  }

  if (memo && !item.memo.includes(memo)) {
    return false;
  }

  return true;
};

export const ADMIN_APPLICATION_ORDERS_SCHOOLS: OrdersSchools = [
  {
    id: 341738760,
    application_id: 123,
    application_name: 'CNN EnglishExpress',
    school_id: 112,
    school_name: 'テスト学校',
    school_year: 2018,
    student_limit: 1000,
    demo_student_limit: 10,
    total_student_limit: 1000 + 10,
    order_type_display: '受注',
    start_at: '2018-04-01T00:00:00+09:00',
    end_at: '2019-04-30T00:00:00+09:00',
    memo: 'text',
    updated_at: '2017-12-01T00:00:00+09:00',
  },
  {
    id: 341738761,
    application_id: 123,
    application_name: 'CNN EnglishExpress',
    school_id: 112,
    school_name: 'テスト学校',
    school_year: 2019,
    student_limit: 1000,
    demo_student_limit: 10,
    total_student_limit: 1000 + 10,
    order_type_display: '受注',
    start_at: '2019-04-01T00:00:00+09:00',
    end_at: '2020-04-30T00:00:00+09:00',
    memo: 'text',
    updated_at: '2018-12-01T00:00:00+09:00',
  },
  {
    id: 341738763,
    application_id: 12345,
    application_name: 'ClassiNOTE',
    school_id: 123,
    school_name: '新宿学校',
    school_year: 2019,
    student_limit: 1000,
    demo_student_limit: 10,
    total_student_limit: 1000 + 10,
    order_type_display: 'デモ(基本サービス未受注)',
    start_at: '2019-04-01T00:00:00+09:00',
    end_at: '2020-04-30T00:00:00+09:00',
    memo: 'text',
    updated_at: '2018-12-01T00:00:00+09:00',
  },
  {
    id: 341738764,
    application_id: 12346,
    application_name: 'EnglishCentral',
    school_id: 11234,
    school_name: 'テスト学校',
    school_year: 2019,
    student_limit: 1000,
    demo_student_limit: 10,
    total_student_limit: 1000 + 10,
    order_type_display: '受注',
    start_at: '2019-04-01T00:00:00+09:00',
    end_at: '2020-04-30T00:00:00+09:00',
    memo: 'memo',
    updated_at: '2018-12-01T00:00:00+09:00',
  },
];

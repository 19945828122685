import release from '../../release.json';

export const environment = {
  production: true,
  home_url: 'https://platform.stg.classi.jp',
  help_url: 'https://support.classi.jp/hc/ja',
  admin_url: 'https://admin.stg.classi.jp',
  students_notice_url: 'https://auth.stg.classi.jp/students/notice',
  parents_notice_url: 'https://auth.stg.classi.jp/parents/notice',
  sentry: 'https://ec60a33d531a4bab874361fbd98d3c40@sentry.io/1383601',
  sentryTracesSampleRate: 0.5,
  environmentName: 'staging',
  sentryReleaseName: undefined,
  deployImageTag: release.deploy_image_tag,
  classi_api_url: '/classi/api',
  admin_api_url: '/admin/api',
  ga4MeasurementId: 'G-7VXZXKEZ8J',
};

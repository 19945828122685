/**
 * [admin] 連携サービスカテゴリーAPIのモック
 */

export const ADMIN_APPLICATION_CATEGORIES = [
  {
    id: 1,
    name: '英語4技能',
    background_color: '#FFFFFF',
  },
  {
    id: 2,
    name: 'プログラミング',
    background_color: '#FFFFFF',
  },
  {
    id: 3,
    name: '部活動支援',
    background_color: '#FFFFFF',
  },
  {
    id: 4,
    name: 'アクティブ・ラーニング',
    background_color: '#FFFFFF',
  },
];

<!-- global menu list -->
<section class="spen-ly-global-menu-list" *ngIf="isOpenMenu">
  <div class="menu-list-background" (click)="toggleMenu()"></div>
  <div class="menu-list-wrapper">
    <div class="spen-util-inner">
      <ng-container *ngIf="globalMenuData.length === 0">
        <p>
          メニューの取得に失敗しました。ページの再読み込みを行なっていただくか、<a [href]="homeUrl">Classiトップ</a
          >のメニューをご利用ください。
        </p>
      </ng-container>
      <div class="menu-item" *ngFor="let menu of globalMenuData">
        <a class="menu-link" [href]="menu.link_url" [target]="menu.target_blank ? '_blank' : '_self'">
          <img class="menu-icon" [src]="menu.icon_url" /><br />
          <div class="spen-mod-circle-badge is-old-red" *ngIf="menu.batch">
            <i class="fa fa-exclamation"></i>
          </div>
          <div class="text" [innerHTML]="menu.title"></div>
        </a>
      </div>
    </div>
  </div>
</section>

/**
 * [admin] 連携サービスディベロッパーのモック
 */

export const ADMIN_DEVELOPERS = [
  {
    id: 1,
    email: 'test1@example.com',
    status: 0,
    phone_number: '03-1111-1111',
    company_name: '開発会社1',
    company_website: 'http://example.com',
  },
  {
    id: 2,
    email: 'test2@example.com',
    status: 0,
    phone_number: '03-2222-2222',
    company_name: '開発会社2',
    company_website: 'http://example.com',
  },
  {
    id: 3,
    email: 'test3@example.com',
    status: 0,
    phone_number: '03-3333-3333',
    company_name: '開発会社3',
    company_website: 'http://example.com',
  },
];

<app-classi-service [isError]="true">
  <div class="spen-mod-heading lv-1">
    <h1 class="heading-text">指定されたページへのアクセスは制限されています。</h1>
  </div>
  <div class="spen-mod-panel">
    <p>
      以下の状況が考えられます。<br />
      ・ご利用中のサービス以外のページへアクセスされている<br />
      利用サービス設定を見直してから、再度アクセスしてください。<br />
      ・一時的にアクセスできない状況になっている<br />
      時間をおいて再度アクセスしてください。
    </p>
  </div>
</app-classi-service>

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * メニュー情報取得APIモック
 * @type {[{title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean} , {title: string; icon_url: string; link_url: string; batch: number; target_blank: boolean}]}
 */
export const MENU = [
  {
    title: '授業記録',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_01.png',
    link_url: 'https://lesson.classi.jp/apps/#/coursework/',
    batch: 0,
    target_blank: false,
  },
  {
    title: '生徒カルテ',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_02.png',
    link_url: 'https://karte.classi.jp/student/',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'Webテスト',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_03.png',
    link_url: 'https://webtest.classi.jp/top',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'Webドリル',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_16.png',
    link_url: 'https://platform.classi.jp/selfstudy',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'アンケート',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_04.png',
    link_url: 'https://platform.classi.jp/questionnaire',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'コンテンツボックス',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_05.png',
    link_url: 'https://platform.classi.jp/contentsbox',
    batch: 0,
    target_blank: false,
  },
  {
    title: '学習動画',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_18.png',
    link_url: 'https://video.classi.jp/',
    batch: 0,
    target_blank: false,
  },
  {
    title: '指導セット',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_06.png',
    link_url: 'https://platform.classi.jp/setlist',
    batch: 0,
    target_blank: false,
  },
  {
    title: '学習記録',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_07.png',
    link_url: 'https://study.classi.jp/',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'カレンダー',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_08.png',
    link_url: 'https://platform.classi.jp/calendar',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'メッセージ',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_09.png',
    link_url: 'https://platform.classi.jp/message',
    batch: 0,
    target_blank: false,
  },
  {
    title: '校内グループ',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_10.png',
    link_url: 'https://platform.classi.jp/group',
    batch: 0,
    target_blank: false,
  },
  {
    title: '校外グループ',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_11.png',
    link_url: 'https://platform.classi.jp/community',
    batch: 1,
    target_blank: false,
  },
  {
    title: '帳票出力',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_15.png',
    link_url: 'https://karte.classi.jp/apps/#/document/',
    batch: 0,
    target_blank: false,
  },
  {
    title: '設定・登録',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_13.png',
    link_url: 'https://setting.classi.jp/',
    batch: 0,
    target_blank: false,
  },
  {
    title: 'ご利用ガイド',
    icon_url: 'https://platform.classi.jp/assets/jgs/shared/img/common/icon_mainmenu_14.png',
    link_url: 'https://platform.classi.jp/guide',
    batch: 0,
    target_blank: false,
  },
];

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserTypeId } from '../../domains';
import { MeService } from '../services/me.service';

@Injectable()
export class TeacherGuard {
  constructor(
    private meService: MeService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.meService.getMe().pipe(
      mergeMap((user) => {
        if (user.user_type_id === UserTypeId.Teacher) {
          return of(true);
        } else {
          this.router.navigate(['/403']);
          return of(false);
        }
      }),
    );
  }
}

export const ADMIN_SETTING_HISTORY = {
  setting_history: [
    {
      id: 341738767,
      user_id: 12345,
      full_name: '新宿 太郎',
      created_at: '2017-12-01T00:00:00+09:00',
    },
    {
      id: 341738768,
      user_id: 22345,
      full_name: '渋谷 太郎',
      created_at: '2017-12-22T00:00:00+09:00',
    },
  ],
};

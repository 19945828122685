import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  back() {
    const backtop = this.route.snapshot.queryParamMap.get('backtop');
    if (backtop) {
      window.location.href = environment.home_url;
    } else {
      this.location.back();
    }
  }
}

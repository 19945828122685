/* eslint-disable */
/**
 * 無料提供教材一覧
 *
 * @see https://github.com/classi/classi_partner/wiki/%5Bclassi%5D-%E9%80%A3%E6%90%BA%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9-%E7%84%A1%E6%96%99%E6%8F%90%E4%BE%9B%E6%95%99%E6%9D%90%E4%B8%80%E8%A6%A7
 */
export const SAMPLE_CONTENTS = {
  total_pages: 10,
  current_page: 1,
  total_count: 123,
  contents: [
    {
      id: 100,
      name: '① (先生用)ワークブック_SOCIAL_CHANGE.pdf',
      kind: 'PDFファイル',
      size: '11MB',
      url: 'https://cdn.classi.jp/pdf/Web%E3%83%86%E3%82%B9%E3%83%88%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E9%85%8D%E4%BF%A1%E3%82%B9%E3%82%B1%E3%82%B7%E3%82%99%E3%83%A5%E3%83%BC%E3%83%AB.pdf',
    },
    {
      id: 101,
      name: '② (先生用)ワークブック_SOCIAL_CHANGE.mp4',
      kind: '動画ファイル',
      size: '100MB',
      url: 'https://cdn.dev.classi.jp/sample_attachements/%E3%82%AA%E3%83%BC%E3%83%AD%E3%83%A9.mp4',
    },
  ],
  application: {
    name: 'SOCIAL CHANGE',
  },
};

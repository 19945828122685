/**
 * 提供中連携サービス一覧
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OauthAllApplication, OauthApplicationCaseStudy, OauthApplicationDetail, OauthApplicationFunction } from '../../domains';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApplicationsService {
  static readonly applicationsUrl = `${environment.classi_api_url}/applications`;

  constructor(private http: HttpClient) {}

  // GET
  getApplications(): Observable<OauthAllApplication> {
    return this.http.get<OauthAllApplication>(ApplicationsService.applicationsUrl);
  }

  // GET 詳細情報
  getApplication(id: number): Observable<OauthApplicationDetail> {
    const url = `${ApplicationsService.applicationsUrl}/${id}`;
    return this.http.get<OauthApplicationDetail>(url);
  }

  // GET 機能情報
  getApplicationFunctions(id: number): Observable<OauthApplicationFunction[]> {
    const url = `${ApplicationsService.applicationsUrl}/${id}/functions`;
    return this.http.get<OauthApplicationFunction[]>(url);
  }

  // GET 事例情報
  getApplicationCaseStudies(id: number): Observable<OauthApplicationCaseStudy[]> {
    const url = `${ApplicationsService.applicationsUrl}/${id}/case_studies`;
    return this.http.get<OauthApplicationCaseStudy[]>(url);
  }
}

/* eslint-disable max-len, @typescript-eslint/quotes */
/**
 * 先生 利用中連携サービス一覧 APIモック
 */
export const TEACHER_APPLICATIONS = [
  {
    id: 12345,
    name: 'EnglishCentral',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/7/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'アダプティブ・ラーニング支援ツール',
    settable: true,
    partnership_type: 'sales',
    manual_video_url: 'https://cdn.dev.classi.jp/sample_attachements/%E3%82%AA%E3%83%BC%E3%83%AD%E3%83%A9.mp4',
    manual_document_url:
      'https://cdn.dev.classi.jp/sample_attachements/%E3%81%B2%E3%82%9A%E3%83%BC%E3%81%A6%E3%82%99%E3%83%BC%E3%81%88%E3%81%B5.pdf',
    is_classi_apps: false,
    is_fungo: false,
  },
  {
    id: 12346,
    name: 'CNN ENGLISH EXPRESS',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/9/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: '英語学習アプリ',
    settable: false,
    partnership_type: 'contents',
    manual_video_url: 'https://cdn.dev.classi.jp/sample_attachements/sample.wma',
    manual_document_url:
      'https://cdn.dev.classi.jp/sample_attachements/%E3%83%95%E3%82%9A%E3%83%AC%E3%82%BB%E3%82%99%E3%83%B3%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B31.pptx',
    is_classi_apps: false,
    is_fungo: false,
  },
  {
    id: 12347,
    name: 'Monaca',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'dummy dummy',
    settable: true,
    partnership_type: 'id_federation',
    manual_video_url: null,
    manual_document_url: null,
    is_classi_apps: false,
    is_fungo: false,
  },
  {
    id: 12348,
    name: '投票ボックス by Classi',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'dummy dummy',
    settable: false,
    partnership_type: 'id_federation',
    manual_video_url: null,
    manual_document_url: 'http://dummy',
    is_classi_apps: true,
    is_fungo: false,
  },
  {
    id: 12349,
    name: 'FunGo',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'dummy dummy',
    settable: true,
    partnership_type: 'id_federation',
    manual_video_url: null,
    manual_document_url: 'http://dummy',
    is_classi_apps: false,
    is_fungo: true,
  },
  {
    id: 12350,
    name: '英作文パス for school',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'dummy dummy',
    settable: true,
    partnership_type: 'id_federation',
    manual_video_url: null,
    manual_document_url: 'http://dummy',
    is_classi_apps: false,
    is_fungo: true,
  },
  {
    id: 12351,
    name: '英語4技能 目標スコア別トレーニング',
    logo_url: 'https://s3-ap-northeast-1.amazonaws.com/classi-staging-app/partner-assets/applications/8/logo.svg',
    web_authentication_url: 'https://xxxxxx/web/start',
    android_authentication_url: 'https://xxxxxx/android/start',
    ios_authentication_url: 'https://xxxxxx/ios/start',
    caption: 'dummy dummy',
    settable: true,
    partnership_type: 'id_federation',
    manual_video_url: null,
    manual_document_url: 'http://dummy',
    is_classi_apps: false,
    is_fungo: true,
  },
];

<app-classi-service [isError]="true">
  <div class="spen-mod-heading lv-1">
    <h1 class="heading-text">お探しのページは見つかりませんでした。</h1>
  </div>
  <div class="spen-mod-panel">
    <p>
      お探しのページは一時的にアクセスできない状況にあるか、<br />
      移動もしくは削除された可能性があります。
    </p>
  </div>
</app-classi-service>

/**
 * ユーザタイプ
 */
export enum UserTypeId {
  Teacher = 1,
  Student = 2,
  Guardian = 3,
}

export enum UserTypeName {
  Teacher = '先生',
  Student = '生徒',
  Guardian = '保護者',
}
